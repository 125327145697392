<template>
    <div>
        <h2>{{ $t("compte.code_parrain") }}</h2>
        <div class="box">
            <b>{{ $t("parrainage.votre_code_parrain") }} :</b> {{ code_parrain }}
        </div>
        <h2>{{ $t("global.filleul") }}</h2>
        <div class="box">
            <CustomTable
                id_table="parrainage_filleul"
                :items="filtredFilleul"
                :busy.sync="table_busy"
                primaryKey="historiquefidelite_filleul"
                :hide_if_empty="true"
                :checkboxes="false"
                :filtre_general="false"
                :display_action_button="false"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Parrainage from "@/mixins/Parrainage.js";
    import CustomTable from "GroomyRoot/components/Table/CustomTable"

    export default {
        name: "ParrainageFilleul",
        mixins: [TableAction, Parrainage],
        props: ['code_parrain'],
        data () {
            return {
                table_busy: true,
                filleul: null
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadComponentFilleul()
            },
            async loadComponentFilleul() {
                this.table_busy = true
                this.filleul = await this.loadFilleul()
                this.table_busy = false
            }
        },
        computed: {
            filtredFilleul: function() {
                return this.filleul
            }
        },
        components: {
            CustomTable
        }
    }
</script>
