import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'

var ParrainageMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadSolde: async function() {
			const url = this.constructRoute(Constants.PARRAINAGE_SOLDE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ParrainageMixin::loadSolde", url)
			.catch(error => {
				console.error("ParrainageMixin::loadSolde => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		loadFilleul: async function() {
			const url = this.constructRoute(Constants.PARRAINAGE_FILLEUL_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ParrainageMixin::loadFilleul", url)
			.catch(error => {
				console.error("ParrainageMixin::loadFilleul => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		loadHistorique: async function() {
			const url = this.constructRoute(Constants.PARRAINAGE_HISTORIQUE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ParrainageMixin::loadHistorique", url)
			.catch(error => {
				console.error("ParrainageMixin::loadHistorique => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		loadCodeParrain: async function() {
			const url = this.constructRoute(Constants.PARRAINAGE_CODE_PARRIN_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ParrainageMixin::loadCodeParrain", url)
			.catch(error => {
				console.error("ParrainageMixin::loadCodeParrain => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
	}
}

export default ParrainageMixin
